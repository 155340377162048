import * as React from "react";
import { FunctionComponent } from "react";
import { Page } from "../components";

const NotFoundPage: FunctionComponent = (): JSX.Element => (
  <Page layout="default" title="Page not found">
    <h1>Page not found.</h1>
  </Page>
);

export default NotFoundPage;
